import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About`}</h1>
    <p>{`Elliot creates moving images the hard way. Based in Melbourne.`}</p>
    <h4>{`Colophon`}</h4>
    <ul>
      <li parentName="ul">{`Set in Söhne by Klim Type Foundry`}</li>
      <li parentName="ul">{`Generated using Gatsby.js`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      